<template>
  <article :class="`ag-block--note-simple3--has-${note.image_display}-image`">
    <span v-if="note.featured_image" class="ag-block__featured-image">
      <Image :media="note.featured_image" :alt="note.title" lazy class="ag-block__featured-image--cover" />
      <Image v-if="note.image_display === 'full'" :media="note.featured_image" :alt="note.title" lazy class="ag-block__featured-image--full" />
    </span>

    <div class="ag-block__body">
      <h3 v-if="note.title" class="ag-block__title">
        <a v-if="note.url" :href="note.url" :target="target" rel="nofollow noindex" v-html="$markdown(note.title)" @click.prevent="onClick(block)" />
        <span v-else v-html="$markdown(note.title)" />
      </h3>
      <p v-if="note.synopsis" class="ag-block__description" v-html="note.synopsis"></p>
      <div v-if="note.url && note.call_to_action_text" class="ag-block__cta">
        <Button :href="note.url" :target="target" as="a" class="ag-block__button" rel="nofollow noindex" @click.prevent="onClick(block)">
          {{ note.call_to_action_text }}
        </Button>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
}>();

const note: NoteBlock = props.block.item;

const target = note?.url_type === "link" ? "_blank" : undefined;

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-block--note-simple3 {
  @apply relative overflow-hidden rounded-lg border;

  .ag-block__featured-image {
    @apply relative flex shrink-0 flex-col md:w-40;

    &--cover,
    &--full {
      @apply block md:aspect-auto md:w-40;
    }

    &--cover {
      @apply relative md:absolute md:inset-y-0 md:h-full md:object-cover;
    }

    &--full {
      @apply relative hidden;
    }
  }

  .ag-block__body {
    @apply w-full p-8;
  }

  &.ag-block--has-featured-image {
    @apply md:flex;
  }

  &--has-full-image {
    .ag-block__featured-image {
      @apply md:py-8 md:pl-4;

      &::before {
        @apply bg-gradient-to-t from-slate-900/20 md:absolute md:inset-y-0 md:left-0 md:z-10 md:block md:w-24 md:backdrop-blur-sm;
        content: "";
      }

      &--cover {
        @apply md:-ml-4 md:w-24;
      }

      &--full {
        @apply rounded-lg md:z-10 md:block;
      }
    }
  }
}
</style>
